/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Table from '../../Components/Table';
import Global from '../../Contexts/Global';
import FlexForm from '../../Components/FlexForm';
import { emptyAutocompleteOptions, getAutocompleteOptions } from '../../redux/actions/autocompleteActions';
import { emptyValidatedOrders, getValidatedOrders, emptyOrderOrigin, emptyOrderDestiny } from '../../redux/actions/orderActions';
import LoadingSpinner from '../../Components/LoadingSpinner';
import useStyles from './style';
import {setFiltersAction} from "../../redux/actions/filterActions";

const initialTableData = {
  titles: [
    { align: 'left', title: 'STATUS' },
    { align: 'left', title: 'ORDER_DATE' },
    { align: 'left', title: 'EXTERNAL_REFERENCE' },
    { align: 'left', title: 'MODEL' },
    { align: 'left', title: 'REGISTRATION_AND_FRAME' },
    { align: 'left', title: 'EXPECTED_PICK_DATE' },
    { align: 'left', title: 'ORIGIN' },
    { align: 'left', title: 'EXPECTED_DELIVERY_DATE' },
    { align: 'left', title: 'DESTINY' },
    { align: 'left', title: 'ATTACHMENT' },
    { align: 'left', title: '' },
  ],
  rows: [],
};

const initialParams = {
  order: 'DESC',
  orderBy: 'ORDER_DATE',
  lastDay: 30,
};

function getColumnName(column) {
  const columns = {
    status: 'status_id',
    model: 'car_model',
    registration_and_frame: 'car_plate_number',
    origin: 'origin_province',
    destiny: 'destination_province',
    documents: 'documents',
    expected_pick_date: 'pickup_expected',
    expected_delivery_date: 'delivery_expected',
  };
  const columnName = columns[column.toLowerCase()]?.toLowerCase() ?? column;
  return columnName;
}

function OrdersInProgress({ dispatch, autocompleteOptions, validatedOrders }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [tableData, setTableData] = useState(initialTableData);
  const [filterValues, setFilterValues] = useState({
    external_reference: '',
    plate_number: '',
  });
  const [params, setParams] = useState(initialParams);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const autocompleteIds = ['provinces', 'statuses'];
  const date = new Date();
  let month = date.getMonth();
  if (month < 10) month = `0${month}`;
  let day = date.getDate();
  if (day < 10) day = `0${day}`;
  useEffect(() => dispatch(setFiltersAction(params)),[params])

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    const newParams = { ...params, order: isAsc ? 'desc' : 'asc', orderBy: getColumnName(column) };
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }
    setOrderProducts(isAsc ? 'desc' : 'asc');
    setParams(newParams);
    dispatch(getValidatedOrders({ params: newParams, setIsLoading }));
  }

  function setAutocompleteOptions(id) {
    let newOptions = [];
    if (autocompleteOptions && autocompleteOptions[id]) {
      newOptions = autocompleteOptions[id].map((option) => ({
        ...option,
        title: option.name,
      }));
    }
    return newOptions;
  }

  function searchValidatedOrders() {
    if (Object.values(params).length) {
      dispatch(getValidatedOrders({ params, setIsLoading }));
    } else {
      dispatch(getValidatedOrders({ setIsLoading }));
    }
  }

  const filtersForm = {
    inputs: [
      {
        label: i18n.t('SHOW'),
        placeholder: i18n.t('ALL'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: [
              {
                title: i18n.t('ALL'),
                id: null
              },
              {
                title: i18n.t('LAST_30_DAYS'),
                id: 30
              },
              {
                title: 'Últimos 60 días',
                id: 60
              },
              {
                title: 'Últimos 90 días',
                id: 90
              },
          ],
          defaultValue: { title: i18n.t('LAST_30_DAYS'), id: 30 },
        },
        onChange: (event, value) => setParams({ ...params, lastDay: value?.id }),
      },
      {
        label: i18n.t('STATUS'),
        placeholder: i18n.t('PENDANT'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: { options: setAutocompleteOptions('statuses').map((option) => ({ ...option, title: i18n.t(option?.title?.toUpperCase()) })) },
        onChange: (event, value) => setParams({ ...params, status: value?.id }),
      },
      {
        label: i18n.t('EXTERNAL_REFERENCE'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.external_reference,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, external_reference: event.target.value });
            setParams({ ...params, reference: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchValidatedOrders(); } },
        },
      },
      {
        label: i18n.t('REGISTRATION_AND_FRAME'),
        placeholder: 'Dato filtrado',
        textFieldProps: {
          value: filterValues.plate_number,
          fullWidth: true,
          onChange: (event) => {
            setFilterValues({ ...filterValues, plate_number: event.target.value });
            setParams({ ...params, frame: event.target.value });
          },
          onKeyPress: (e) => { if(e.key === 'Enter'){ e.preventDefault(); searchValidatedOrders(); } },
        },
      },
      {
        label: i18n.t('ORIGIN'),
        placeholder: i18n.t('ACTIVE'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: setAutocompleteOptions('provinces').sort((a, b) => a.title.localeCompare(b.title)),
        },
        onChange: (event, value) => setParams({ ...params, origin: value?.title }),
      },
      {
        label: i18n.t('DESTINY'),
        placeholder: i18n.t('ACTIVE'),
        textFieldProps: { fullWidth: true },
        autoCompleteProps: {
          options: setAutocompleteOptions('provinces').sort((a, b) => a.title.localeCompare(b.title)),
        },
        onChange: (event, value) => setParams({ ...params, destination: value?.title }),
      },
    ],
    buttons: [
      { label: i18n.t('SEARCH'), buttonProps: { variant: 'contained' }, onClick: () => searchValidatedOrders() },
    ],
  };

  useEffect(() => {
    if (validatedOrders) {
      setTableData({
        ...tableData,
        rows: Object.values(validatedOrders).map((order) => ({
          status: order.status?.name && order.status?.color ? {
            title: i18n.t(order.status?.name?.toUpperCase()),
            name: order.status?.name,
            coloredText: true,
            color: order.status?.color,
            padding: true,
          } : '',
          orderDate: order.tracking_order_date?.slice(0, 10).split('-').reverse().join('-') || '',
          externalReference: order.external_reference || '',
          model: order.car_model || '',
          registration: order.car_plate_number || '',
          expectedPickDate: order.tracking_pickup_expected?.slice(0, 10).split('-').reverse().join('-') || '',
          origin: order.origin_province || order.origin?.province?.name || order.location_origin_province || '',
          expectedDeliveryDate: order.tracking_delivery_expected?.slice(0, 10).split('-').reverse().join('-') || '',
          destiny: order.destination_province || order.destination?.province?.name || order.location_destination_province || '',
          documents: order.documents ? "Sí" : "No",
          link: {
            title: 'Ver detalle',
            reference: 'Detail',
            link: true,
            url: `/order-in-progress/${order.order_id}`,
            label: 'detail',
          },
        })),
      });
    }
  }, [validatedOrders]);

  useEffect(() => {
    dispatch(getValidatedOrders({ params, setIsLoading }));
    autocompleteIds.forEach((id) => {
      dispatch(getAutocompleteOptions(id));
    });
  }, []);

  useEffect(() => () => {
    dispatch(emptyValidatedOrders());
    dispatch(emptyAutocompleteOptions());
  }, []);

  return (
    <Paper>
      <div className={classes.mainContainer}>
        <FlexForm form={filtersForm} />
        <div style={{ marginTop: '20px' }}>
          {isLoading
            ? (
              <LoadingSpinner />
            )
            : (
              <Table
                tableData={tableData}
                hasStatusColors
                orderBy={orderBy}
                orderProducts={orderProducts}
                onOrderProducts={handleRequestSort}
              />
            )}
        </div>
      </div>
    </Paper>
  );
}

OrdersInProgress.propTypes = {
  dispatch: PropTypes.func.isRequired,
  autocompleteOptions: PropTypes.shape({}).isRequired,
};

function mapStateToProps({ orderReducer, autocompleteReducer }) {
  return {
    validatedOrders: orderReducer.validatedOrders,
    autocompleteOptions: autocompleteReducer.autocompleteOptions,
  };
}

export default connect(mapStateToProps)(OrdersInProgress);

import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';

function loadError(error) {
  console.log('ERROR', error);
}

function loadOrders(orders) {
  return {
    type: actionTypes.LOAD_ORDERS,
    data: orders,
  };
}

export function getOrders({ params, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    let endpoint = 'orders';

    if (params instanceof Object && Object.keys(params).length) {
      const filters = [];

      Object.keys(params).forEach((key, index) => {
        if (params[key] || params[key] === 0) {
          if (index !== 0) {
            filters.push('&');
          }
          filters.push(`${key}=${params[key]}`);
        }
      });

      endpoint += `?${filters.join('')}`;
    }

    try {
      const response = await apiClient.get(endpoint);
      if (response.status === 200 || response.status === 204) {
        dispatch(loadOrders(response.data.data));
      }
    } catch (error) {
      loadError(error);
    }
    setIsLoading(false);
  };
}

function loadValidatedOrders(orders) {
  return {
    type: actionTypes.LOAD_VALIDATED_ORDERS,
    data: orders,
  };
}

function loadValidatedOrderDetail(orders) {
  return {
    type: actionTypes.LOAD_VALIDATED_ORDER_DETAIL,
    data: orders,
  };
}

export function getValidatedOrders({ params, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    let endpoint = 'validated-orders';

    if (params instanceof Object && Object.keys(params).length) {
      const filters = [];

      Object.keys(params).forEach((key, index) => {
        if (params[key] || params[key] === 0) {
          if (index !== 0) {
            filters.push('&');
          }
          filters.push(`${key}=${params[key]}`);
        }
      });

      endpoint += `?${filters.join('')}`;
    }

    try {
      const response = await apiClient.get(endpoint);
      if (response.status === 200 || response.status === 204) {
        console.log("loadValidatedOrders", response.data.data);
        dispatch(loadValidatedOrders(response.data.data));
      }
    } catch (error) {
      loadError(error);
    }
    setIsLoading(false);
  };
}

export function getValidatedOrderDetail({ requestNumber, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    if (requestNumber) {
      const endpoint = 'orders/' + requestNumber;
      try {
        const { status, data } = await apiClient.get(endpoint);
        if (status === 200) {
          
          //Check if object is wrong
          if(!Array.isArray(data.data['cars'])){
            let prevCars = data.data['cars'];
            data.data['cars'] = [];
            for(var i in prevCars) data.data['cars'].push(prevCars[i])
          }
          
          dispatch(loadValidatedOrderDetail(data.data));
        }
      } catch (error) {
        dispatch(loadError(error));
      }
    }
    setIsLoading(false);
  };
}

export function emptyOrders() {
  return {
    type: actionTypes.EMPTY_ORDERS,
  };
}

export function emptyValidatedOrders() {
  return {
    type: actionTypes.EMPTY_VALIDATED_ORDERS,
  };
}

function loadTracking(tracking) {
  return {
    type: actionTypes.LOAD_TRACKING,
    data: tracking,
  };
}

export function getTracking(orderID) {
  return async (dispatch) => {
    if (orderID) {
      const endpoint = 'tracking?order_id=' + orderID;
      try {
        const { status, data } = await apiClient.get(endpoint);
        if (status === 200) {
          dispatch(loadTracking(data.data));
        }
      } catch (error) {
        dispatch(loadError(error));
      }
    }
  };
}

export function loadOrigin(orderOrigin){
  return{
    type: actionTypes.LOAD_ORIGIN,
    data: orderOrigin,
  }
}
export function emptyOrigin() {
  return {
    type: actionTypes.EMPTY_ORIGIN,
  };
}

export function loadDestiny(orderDestiny){
  return{
    type: actionTypes.LOAD_DESTINY,
    data: orderDestiny,
  }
}
export function emptyDestiny() {
  return {
    type: actionTypes.EMPTY_DESTINY,
  };
}


export function getOrderOrigin ({ requestNumber, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    if (requestNumber) {
      const originLoc = 'locations/'+requestNumber+'/'+1;
      try{
        const { status, data } = await apiClient.get(originLoc);
        if(data.data){ dispatch(loadOrigin(data.data)); } 
      } catch (error){
        dispatch(loadError(error));
      }
    }
    setIsLoading(false);
  }
}

export function getOrderDestiny ({ requestNumber, setIsLoading = () => {} }) {
  setIsLoading(true);
  return async (dispatch) => {
    if (requestNumber) {
      const destinationLoc = 'locations/'+requestNumber+'/'+0;
      try{
        const { status, data } = await apiClient.get(destinationLoc);
        if(data.data){ dispatch(loadDestiny(data.data)); } 
      } catch (error){
        dispatch(loadError(error));
      }
    }
    setIsLoading(false);
  }
}